import React from "react"
import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Width,
  Link,
  Text,
  Instagram,
  MentorFooter,
  MentorHeader,
} from "@/components/index"
import Img from "gatsby-image"

export default function Mentorship({ data }) {
  return (
    <Layout headerType="checkout" headerTheme="light" footerTheme="light">
      <SEO
        title="Meet Sonora’s Mentors: Josh Sklair"
        description="Sonora pairs world-class mentors with intermediate guitar students in an intensive 3-month program to help them find their breakthrough on guitar."
      />
      <MentorHeader
        name="Josh Sklair"
        videoUrl="https://vimeo.com/498092024/f18af3213f"
      />
      <Width pt="3.6rem" pb="7.2rem">
        <Box
          width={["100%", null, 10 / 12, null]}
          mx="auto"
          display="flex"
          flexDirection={["column", null, "row", null]}
        >
          <Box width={["100%", null, 4 / 12, null]} pr="2em">
            <Box borderRadius=".6rem" overflow="hidden">
              <Img
                alt={"curt"}
                fluid={data.joshSklairImage.childImageSharp.fluid}
              />
            </Box>
          </Box>
          <Box width={["100%", null, 8 / 12, null]}>
            <Text as="h1" fontWeight="bold" mb="1.2rem" mt={["2rem", null, "0rem", null]}>
              Josh Sklair
            </Text>
            <Text level="1" mb="1.2rem">
              Two-time <strong>Grammy Award</strong> winning Guitarist
            </Text>
            <Box>
              <Link to="https://www.instagram.com/joshsklairguitar/" as="a">
                <Instagram />
              </Link>
            </Box>
            <Text level="2" mt="3.6rem">
              Josh Sklair is a two-time <strong>Grammy Award</strong> winner,
              honored during his 20+ years collaboration as band leader,
              guitarist, arranger and co-writer with American Music icon{" "}
              <strong>Etta James</strong>.
            </Text>
            <Text level="2">
              After briefly attending <strong>Berklee College of Music</strong>{" "}
              – Boston in 1978, Josh graduated from{" "}
              <strong>Howard Roberts’ Guitar Institute of Technology</strong> –
              Los Angeles (later “Musician’s Institute”/ M.I.).
            </Text>
            <Text level="2">
              Through the 80s and 90s Josh was first-call guitar player for{" "}
              <strong>Dick Clark’s Good Old Rock ‘n’ Roll Show</strong> House
              Band – the <strong>Brother John (Goines) Band</strong> – backing
              the legends:{" "}
              <strong>
                Chuck Berry, Bo Diddley, Del Shannon, the Shirelles, Darlene
                Love, Lowell Fulson, Henry Butler, Lee Allen, Leo Nocentelli,
                Little Anthony, Mary Wells, Martha Reeves, the Coasters, the
                Platters, the Rivingtons, Bobby Vee, Bobby Rydell, Bobby Sheene,
                The Drifters, The Olympics, Fabian & Freddy Cannon.
              </strong>
              Valuable experience gained playing with these music pioneers gave
              Josh a solid foundation in the roots of Rock, Soul and Rhythm &
              Blues.
            </Text>
            <Text level="2">
              Between regular touring and recording over a dozen studio albums
              with Etta, Josh maintained a solid career in Los Angeles,
              recording and/or performing with{" "}
              <strong>
                Jon Lucien, Sophie B. Hawkins, Jim Belushi & Dan Aykroyd/the
                Blues Brothers, Jeffrey Osborne, the H.B. Barnum Orchestra,
                Aretha Franklin, Stevie Wonder,
              </strong>{" "}
              and many others.
            </Text>
            <Text level="2">
              Josh’s guitar tracks, production and/or compositions can also be
              heard on numerous Movie Soundtracks, Television Shows and
              Commercials in addition to solo albums “Josh” on VTL, “After
              Hours” and “Internal Voices”.
            </Text>
          </Box>
        </Box>
      </Width>
      <MentorFooter data={data} name="Josh Sklair" />
    </Layout>
  )
}

/* Image and Other GQL Queries */
export const mentorshipQuery = graphql`
  query {
    joshSklairImage: file(
      relativePath: { eq: "joshSklair-L.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    levelupImage: file(relativePath: { eq: "levelup.png" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    curriculumImage: file(relativePath: { eq: "curriculum.png" }) {
      childImageSharp {
        fluid(maxWidth: 570, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    communityImage: file(relativePath: { eq: "community.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    locationImage: file(relativePath: { eq: "location.png" }) {
      childImageSharp {
        fixed(width: 14, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
